import "./style/App.scss";
import NavBar from "./components/navBar";
import Main from "./components/main";
import SocialLinks from "./components/socialLinks";
import Countries from "./components/countries";
import Contacts from "./components/contact";
import Footer from "./components/footer";

function App() {
	return (
		<div className="App">
			<NavBar />
			<Main />
			<Countries />
			<SocialLinks />
			<Contacts />
			<Footer />
		</div>
	);
}

export default App;
