import React from "react";
import logo from "../assets/images/wlogo.png";

function Footer() {
	return (
		<div>
			<div className="footer">
				<img src={logo} alt="logo" />
				<h4>All services are licenced.</h4>
				<h5>
					Developed & Designed by <a href="http://t.me/modev">Mo</a>
				</h5>
			</div>
		</div>
	);
}

export default Footer;
