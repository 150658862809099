import React from "react";

function Main() {
	return (
		<div>
			<div className="main-page">
				<div className="fake"></div>
				<div className="info">
					<h1>The easiest way to Europe!</h1>
					<button>
						<a href="http://t.me/dstudyuz">
							Contact Us <i className="fas fa-plane"></i>
						</a>
					</button>
				</div>
			</div>
		</div>
	);
}

export default Main;
