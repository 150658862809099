import React from "react";
import logo from "../assets/images/logo.png";


function NavBar() {
	return (
		<div>
			<div className="navbar">
				<div className="brand">
					<img src={logo} alt="" />
				</div>
				<ul className="nav-menu">
					<div className="line"></div>
					<li>
						<span>Home</span>
					</li>
					<div className="line"></div>
					<li>
						<span>Services</span>
					</li>
					<div className="line"></div>
					<li>
						<span>Contact</span>
					</li>
					<div className="line"></div>
				</ul>
				{/* <i className="fas fa-bars"></i> */}
			</div>
			<div className="mobile-menu">
				<ul className="nav-menu-mobile">
					<div className="line"></div>
					<li>
						<span>Home</span>
					</li>
					<div className="line"></div>
					<li>
						<span>Services</span>
					</li>
					<div className="line"></div>
					<li>
						<span>Contact</span>
					</li>
					<div className="line"></div>
				</ul>
			</div>
		</div>
	);
}
export default NavBar;
