import React from "react";

function SocialLinks() {
	return (
		<div className="social-links">
			<a href="http://instagram.com/dstudyuz">
				<i className="fab fa-instagram"></i>
			</a>
			<div className="line1"></div>
			<a href="http://t.me/dstudyuz">
				<i className="fab fa-telegram"></i>
			</a>
			<div className="line1"></div>
			<a href="tel:952034440">
				<i className="fas fa-phone fa-sm"></i>
			</a>
		</div>
	);
}

export default SocialLinks;
