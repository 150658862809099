import React from "react";

function Contacts() {
	return (
		<div>
			<div className="contacts">
				<div className="line3"></div>
				<h1>Contact Us</h1>
				<div className="line3"></div>
				<iframe
					title="Google Forms"
					src="https://docs.google.com/forms/d/e/1FAIpQLSdZcaUxH0gmvX1A1hv5q3SI0piXoyuslOJWC_nqc3UuFoF6Jg/viewform?embedded=true"
					width="640"
					height="975"
					frameborder="0"
					marginheight="0"
					marginwidth="0"
				>
					Loading…
				</iframe>
			</div>
		</div>
	);
}

export default Contacts;
