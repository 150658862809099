import React from "react";

function Countries() {
	return (
		<div>
			<section class="countries">
				<div className="line3"></div>
				<h1>Our Services</h1>
				<div className="line3"></div>
				<div class="country-list">
					<div class="ser ser-1 item" data-aos="fade-right">
						<h3>Czech Republic</h3>
						<p>
							If you’re looking for a unique European study abroad experience,
							look no further than the Czech Republic. Known for its impressive
							castles, vast selection of native beers, medieval old towns, and
							beautiful landscapes, the Czech Republic combines the best of East
							and West Europe to create its own hybrid flair.
						</p>
					</div>
					<div class="ser ser-2 item" data-aos="zoom-in">
						<h3>Latvia</h3>
						<p>
							Latvia is one of Europe’s hidden gems for higher education: With
							its safe and welcoming cities and spectacular coastline it is
							ideal for students and visitors alike. Located on the Baltic Sea,
							Latvia is somewhat of a crossroads between East and West, and in
							this cosmopolitan country, a lot of languages are spoken -
							Latvian, Russian, German, and of course English.
						</p>
					</div>
					<div class="ser ser-3 item" data-aos="fade-left">
						<h3>Slovakia</h3>
						<p>
							Slovakia's small size and mountainous terrain is possibly its
							biggest attraction, with almost 80 per cent of land 720m above sea
							level. It’s dinky capital, Bratislava, can not only boast
							interesting history but is at the centre of the most dynamically
							developing regions, home to both quirky cafés and thick forests.
							At present there are 20 public universities, 10 private
							universities and three state universities and colleges in
							Slovakia.
						</p>
					</div>
					<div class="ser ser-4 item" data-aos="fade-right">
						<h3>Poland</h3>
						<p>
							Poland is a large country with a rich history, a vibrant culture,
							and welcoming people. Thanks to recent efforts in
							internationalisation, Poland is attracting ever more students from
							abroad. The country offers world-class education at modest tuition
							fees and remarkably affordable cost of living.
						</p>
					</div>
					<div class="ser ser-5 item" data-aos="zoom-in">
						<h3>Lithuania</h3>
						<p>
							With its colourful history and its important geographic location,
							Lithuania is the bustling connecting hub between Western and
							Eastern Europe. The small nation with a population of just about
							2.8 million has been an EU member since 2004 and, in 2015, adopted
							the Euro as its official currency. Over the past two decades,
							Lithuania’s education system has seen exciting reforms. Today,
							there are hundreds of interesting English-taught study options
							available to international students.
						</p>
					</div>
					<div class="ser ser-6 item" data-aos="fade-left">
						<h3>Germany</h3>
						<p>
							Germans somewhat ironically call their own country “the country of
							poets and thinkers”. Nonetheless: There are around 400
							institutions of higher educations in Germany, many of which offer
							English-taught study programmes - about 1,000 in total. Many
							German universities score high in international rankings.
						</p>
					</div>
					<div class="ser ser-7 item" data-aos="fade-right">
						<h3>South Korea</h3>
						<p>
							South Korea welcomes international students with open arms.
							Universities offer reasonable tuition fees and plenty of
							scholarships, which has created vibrant and diverse student
							communities in all of the country’s major cities.
						</p>
					</div>
					<div class="ser ser-8 item" data-aos="zoom-in">
						<h3>Russia</h3>
						<p>
							Study in Russia is your most popular resource dedicated to help
							foreign students looking for education in Russian universities
							with all information they need in order to make informed decisions
							about Russia. You can study in English or in Russian language its
							your choice to choose!
						</p>
					</div>
					<div class="ser ser-9 item" data-aos="fade-left">
						<h3>Turkey</h3>
						<p>
							You have many reasons to study in Turkey. Turkey is a center of
							attraction for students in the world, with its natural, spiritual,
							transportation and physical properties specific to its cities.
						</p>
					</div>
				</div>
			</section>
		</div>
	);
}
export default Countries;
